/*
This module is based on the example provided by google in their
IMA SDK docs : https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side

It must be enabled on specific posts with the tag `serve-preroll-ads`.

More documentation can be found here: client/docs/pre-roll-video-ads.md

TODOS:
* Consider moving this into a mixin along with some of the stuff in PostComponent.
*/

let adsLoaded = false;
let adsManager;
let adDisplayContainer;
let videoElement;
let adContainer;
let adsLoader;
let adsActive;
let isPlaying;
let adControlsContainer;
let playbackControlButton;
let audioControlButton;
let wistiaVideoObject;
let enableAutoplay;
let observer;
const PLAY = '<svg xmlns="http://www.w3.org/2000/svg"'
+ 'viewBox="0 0 48 48"><path d="m9.26 45.02c-.35 0-.7-.09-1.01-.27-.61-.36-.99-1.02-.99-1.73v-37.88c0-.71.38-1.36.99-1.72s1.37-.37'
+ '1.99-.02l33.94 18.84c.63.35 1.03 1.02 1.03 1.74s-.39 1.39-1.02 1.75l-33.95 19.03c-.3.17-.64.26-.98.26zm2-36.48v31.06l27.83-15.61z"'
+ 'fill="#002e3b"/></svg>';
const PAUSE = '<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 42.53H7.56c-1.1 0-2-.9-2-2V7.63c0-1.1.9-2 '
+ '2-2h11.95c1.1 0 2 .9 2 2v32.9m19.64 2H29.2c-1.1 0-2-.9-2-2V7.63c0-1.1.9-2 2-2h11.95" fill="#002e3b"/></svg>';
const SPEAKER_MUTE = '<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">'
+ '<g fill="#002e3b"><path d="m28.35 43.69c-.34 0-.68-.09-.98-.26l-14.88-8.41c-.5-.28-1.06-.43-1.62-.43h-8.81c-1.1'
+ ' 0-2-.9-2-2v-16.15c0-1.1.9-2 2-2h8.8c.57 0 1.13-.15 1.63-.43l14.88-8.44c.62-.35 1.38-.35 1.99.01.62.36.99 1.02.99'
+ ' 1.73v34.38c0 .71-.38 1.37-.99 1.73-.31.18-.66.27-1.01.27zm-24.29-13.09h6.8c1.26 0 2.5.33 3.59.95l11.9 6.72v-27.52l-11.89'
+ ' 6.75c-1.1.62-2.34.95-3.6.95h-6.8z"/><path d="m43.54 24.2 3.8-3.8c.78-.78.78-2.05 0-2.83s-2.05-.78-2.83 0l-3.8 3.8-3.8-3.8c-.7'
+ '8-.78-2.05-.78-2.83 0s-.78 2.05 0 2.83l3.8 3.8-3.8 3.8c-.78.78-.78 2.05 0 2.83.39.39.9.59 1.41.59s1.02-.2 1.41-.59l3.8-3.8 3.8'
+ ' 3.8c.39.39.9.59 1.41.59s1.02-.2 1.41-.59c.78-.78.78-2.05 0-2.83l-3.8-3.8z"/></g></svg>';
const SPEAKER_SOUND = '<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">'
+ '<g fill="#002e3b"><path d="m28.35 43.69c-.34 0-.68-.09-.98-.26l-14.88-8.41c-.5-.28-1.06-.43-1.62-.43h-8.81c-1.1'
+ ' 0-2-.9-2-2v-16.15c0-1.1.9-2 2-2h8.8c.57 0 1.13-.15 1.63-.43l14.88-8.44c.62-.35 1.38-.35 1.99.01.62.36.99 1.02.99'
+ ' 1.73v34.38c0 .71-.38 1.37-.99 1.73-.31.18-.66.27-1.01.27zm-24.29-13.09h6.8c1.26 0 2.5.33 3.59.95l11.9'
+ ' 6.72v-27.52l-11.89 6.75c-1.1.62-2.34.95-3.6.95h-6.8z"/><path d="m43.22 39.27c-.28 0-.56-.06-.83-.18-1-.46-1.45-1.64-.99-2.65'
+ ' 5.63-12.33.07-24.19.02-24.31-.48-.99-.06-2.19.93-2.67s2.19-.06 2.67.93c.27.55 6.45 13.62.02 27.71-.34.74-1.06 1.17-1.82 1.17z"/>'
+ '<path d="m36.69 35.11c-.28 0-.56-.06-.83-.18-1-.46-1.45-1.64-.99-2.65 3.7-8.1.05-15.92.02-16-.47-.99-.06-2.19.94-2.67.99-.48'
+ ' 2.18-.06 2.67.93.19.39 4.52 9.54.02 19.39-.34.74-1.06 1.17-1.82 1.17z"/></g></svg>';

const encodeCustomParams = (targetingData) => `ugc%3D${targetingData.ugc}%26tag%3D${targetingData.tag?.join('%2C')}`;

const vastParams = (networkId, targetingData) => ({
  // Documentation for these params can be found here: https://support.google.com/admanager/answer/10678356?hl=en
  iu: `/${networkId}/${targetingData.ad_prefix}`, // ad unit
  sz: '640x360', // master size for video slot
  ciu_szs: '640x360%7C300x250', // sizes
  gdfp_req: 1, // user is on ad manager schema
  output: 'xml_vast2', // vast format
  vpos: 'preroll', // video position
  cust_params: encodeCustomParams(targetingData), // custom params
  unviewed_position_start: 1, // should be 1
  env: 'instream', // insteam or vp
  plcmt: 1, // can be 1 or 2. 1 means in stream
  wta: 0, // ad badging on or off
  vpmute: 0, // mute player on or off
  vpa: 'click', // auto or click to play
  url: window.location.origin + window.location.pathname, // url of the page
  description_url: window.location.origin + window.location.pathname, // url of the page
  hl: 'en', // language
  correlator: new Date().getTime(), // needs to be a unique value each time
  scor: new Date().getTime(), // page view correlator
  // Below are a bunch of properties that were included in the sorta working
  // URL that someone from Google or Operative gave us. Removing them doesn't
  // seem to affect anything but I'm leaving them here for now as documentation.
  // sdkv: 'h.3.656.2', // not documented
  // osd: 2, // not documented
  // frm: 0, // not documented
  // vis: 2, // not documented
  // sdr: 1, // not documented
  // afvsz: '450x50%2C468x60%2C480x70', // non-linear ad slot sizes - we can probably remove this
  // psd: 'W251bGwsbnVsbCxudWxsLDFd', // not documented
  // is_amp: 0, // not documented
  // uach: 'WyJtYWNPUyIsIjEzLjIuMSIsImFybSIsIiIsIjEyNy4wLjY1MzMuNzMiLG51bGwsMCxudWxsLCI2NCIsW1siTm90KUE7QnJhbmQiLCI5OS4wLjAuMCJdLFsiR29vZ2xlIENocm9tZSIsIjEyNy4wLjY1MzMuNzMiXSxbIkNocm9taXVtIiwiMTI3LjAuNjUzMy43MyJdXSwwXQ..', // not documented
  // u_so: 'l', // not documented
  // ctv: 0, // not documented
  // sdki: 445, // not documented
  // ptt: 20, // not documented
  // adk: 2764283186, // not documented
  // sdk_apis: '2%2C7%2C8', // should not be used in IMA SDK
  // omid_p: 'Google1%2Fh.3.656.2', // should not be used in IMA SDK
  // media_url: 'blob%3Ahttps%253a%2F%2Fmigraine.local.healthunion.io%2Fd61eceaa-3461-4392-94b0-96fe612c5287', // not documented
  // sid: '*', // not supported for web
  // nel: 1, // not documented
  // td: 1, // not documented
  // eid: '95322027%2C95322907%2C95326337%2C95331589%2C95332046%2C95338844', // not documented
  // top: window.location.origin + window.location.pathname, // not documented
  // loc: window.location.origin + window.location.pathname, // not documented
  // dt: 1723134114973, // not documented
  // cookie: '*', // not documented
  // gpic: '*', // not documented
  // eo_id_str: '*', // not documented
  // pvsid: 4040410073130342, // not documented
  // ged: 've4_td71929_tt71928_pd71929_la71929000_er1473.205.1627.505_vi211.0.964.1426_vp0_ts0_eb16427', // not documented
});

const setupAutoPlayObserver = () => {
  observer = new IntersectionObserver((videos) => {
    videos.forEach((video) => {
      if (!video.isIntersecting) return;

      observer.unobserve(video.target);
      wistiaVideoObject.mute();
      adsManager.setVolume(0);
      videoElement.play();
    });
  }, { threshold: 0.75 });

  observer.observe(videoElement);
};

const resizeAd = () => {
  if (adsManager) {
    const width = videoElement.clientWidth;
    const height = videoElement.clientHeight;
    adsManager.resize(width, height, window.google.ima.ViewMode.NORMAL);
  }
};

const onAdError = (adErrorEvent) => {
  // eslint-disable-next-line no-console
  console.log(adErrorEvent.getError());
  if (adsManager) {
    adsManager.destroy();
  }
};

const toggleWrapperActiveClass = () => {
  const activeClass = 'wistia-pre-roll-ads__wrapper--ad-active';
  const wistiaWrapper = videoElement.closest('.wistia_embed');

  if (!wistiaWrapper) return;

  if (adsActive) {
    wistiaWrapper.classList.add(activeClass);
  } else {
    wistiaWrapper.classList.remove(activeClass);
  }
};

const onAdStart = () => {
  adsActive = true;
  isPlaying = true;
  adContainer.style.zIndex = '1';
  playbackControlButton.innerHTML = PAUSE;
  toggleWrapperActiveClass();
};

const onAdComplete = () => {
  adsActive = false;
  adControlsContainer.remove();
  wistiaVideoObject.volume(adsManager.getVolume());
  adContainer.style.zIndex = '0';
  toggleWrapperActiveClass();
};

const onContentPauseRequested = () => {
  videoElement.pause();
  isPlaying = false;
};

const onContentResumeRequested = () => {
  videoElement.play();
  isPlaying = true;
};

const onAdPause = () => {
  isPlaying = false;
  playbackControlButton.innerHTML = PLAY;
};

const onAdResume = () => {
  isPlaying = true;
  playbackControlButton.innerHTML = PAUSE;
};

const onVolumeChange = () => {
  const adVolume = adsManager.getVolume();

  if (adVolume > 0) {
    audioControlButton.innerHTML = SPEAKER_SOUND;
    wistiaVideoObject.unmute();
  } else {
    audioControlButton.innerHTML = SPEAKER_MUTE;
    wistiaVideoObject.mute();
  }
};

const setAdsManagerEvents = () => {
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.STARTED,
    onAdStart,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.COMPLETE,
    onAdComplete,
  );
  adsManager.addEventListener(
    window.google.ima.AdErrorEvent.Type.AD_ERROR,
    onAdError,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
    onContentPauseRequested,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
    onContentResumeRequested,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.PAUSED,
    onAdPause,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.RESUMED,
    onAdResume,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.VOLUME_CHANGED,
    onVolumeChange,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.VOLUME_MUTED,
    onVolumeChange,
  );
};

const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
  // Instantiate the AdsManager from the adsLoader response and pass it the video element
  adsManager = adsManagerLoadedEvent.getAdsManager(videoElement);

  // Set up events and observers for the Ad
  setAdsManagerEvents();
  if (enableAutoplay) setupAutoPlayObserver();
};

const setAdsLoaderEvents = () => {
  adsLoader.addEventListener(
    window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
    onAdsManagerLoaded,
    false,
  );
  adsLoader.addEventListener(
    window.google.ima.AdErrorEvent.Type.AD_ERROR,
    onAdError,
    false,
  );
};

const requestAds = (networkId, targetingData) => {
  const adsRequest = new window.google.ima.AdsRequest();

  if (targetingData?.tag && targetingData.tag.includes('test-preroll-ads')) {
    // Below is a 10 second, google-provided test ad for development and
    // debugging purposes. Ad the tag 'test-preroll-ads' to a post (in
    // addition to the serve-preroll-ads tag) to use this instead of actual
    // targeting data
    adsRequest.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?'
      + 'iu=/21775744923/external/single_ad_samples&sz=640x480&'
      + 'cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&'
      + 'gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
  } else {
    const params = new URLSearchParams(vastParams(networkId, targetingData)).toString();
    adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?${decodeURI(params)}`;
  }

  // Specify the linear and nonlinear slot sizes. This helps the SDK to
  // select the correct creative if multiple are returned.
  adsRequest.linearAdSlotWidth = videoElement.clientWidth;
  adsRequest.linearAdSlotHeight = videoElement.clientHeight;
  adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
  adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;
  adsRequest.pageUrl = window.location.origin + window.location.pathname;

  // Pass the request to the adsLoader to request ads
  adsLoader.requestAds(adsRequest);
};

const initializeIMA = (video, networkId, targetingData) => {
  // This setting prevents ad video failures in some cases on iOS
  // See docs: https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/skippable-ads
  window.google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);

  adDisplayContainer = new window.google.ima.AdDisplayContainer(adContainer, video);
  adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);

  setAdsLoaderEvents();

  video.addEventListener('ended', () => {
    adsLoader.contentComplete();
  });

  requestAds(networkId, targetingData);
};

const handleAudioControlClick = () => {
  if (!adsActive) return;
  const adVolume = adsManager.getVolume();

  if (adVolume === 0) {
    adsManager.setVolume(1);
  } else {
    adsManager.setVolume(0);
  }
};

const handlePlaybackControlClick = () => {
  if (!adsActive) return;

  if (isPlaying) {
    adsManager.pause();
  } else {
    adsManager.resume();
  }
};

const setupAdContainerEventsForVideoControls = () => {
  adControlsContainer = document.createElement('div');
  adControlsContainer.className = 'wistia-pre-roll-ads__ad-controls';

  // makes button for playback control (pause/play)
  playbackControlButton = document.createElement('button');
  playbackControlButton.className = 'wistia-pre-roll-ads__button wistia-pre-roll-ads__button--playback';
  playbackControlButton.innerHTML = isPlaying ? PAUSE : PLAY;

  // makes button for mute/unmute
  audioControlButton = document.createElement('button');
  audioControlButton.className = 'wistia-pre-roll-ads__button wistia-pre-roll-ads__button--audio';
  audioControlButton.innerHTML = adsManager.getVolume() > 0 ? SPEAKER_SOUND : SPEAKER_MUTE;

  adControlsContainer.appendChild(audioControlButton);
  adControlsContainer.appendChild(playbackControlButton);
  adContainer.appendChild(adControlsContainer);

  // click events for audio button
  audioControlButton.addEventListener('click', handleAudioControlClick);
  //  click events for playback
  playbackControlButton.addEventListener('click', handlePlaybackControlClick);
};

const loadAds = (event) => {
  if (adsLoaded) return;
  adsLoaded = true;

  if (wistiaVideoObject.isMuted()) {
    adsManager.setVolume(0);
  } else {
    adsManager.setVolume(wistiaVideoObject.volume());
  }

  // Prevent triggering immediate playback when ads are loading
  event.preventDefault();
  videoElement.pause();

  // Initialize the container. Must be done via a user action on mobile devices.
  // videoElement.load(); // This was included in google's example code but it's
  // preventing the ad from pausing during the ad so I've commented it out for now.
  adDisplayContainer.initialize();

  const width = videoElement.clientWidth;
  const height = videoElement.clientHeight;
  // eslint-disable-next-line no-underscore-dangle

  try {
    adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
    adsManager.start();
    setupAdContainerEventsForVideoControls();
  } catch (adError) {
    // Play the video without ads, if an error occurs
    adsActive = false;
    // eslint-disable-next-line no-console
    console.log('AdsManager could not be started', adError);
    videoElement.play();
  }
};

const injectAdContainer = () => {
  if (!videoElement) return;
  // This creates a div to house the ad and cover the video.
  adContainer = document.createElement('div');
  adContainer.id = 'wistia-pre-roll-ads__ad-container';
  videoElement.after(adContainer);
};

const setup = (videoObject, networkId, targetingData) => {
  wistiaVideoObject = videoObject;
  videoElement = document.querySelector('[id^=wistia_simple_video_]');
  injectAdContainer();
  initializeIMA(videoElement, networkId, targetingData);
  videoElement.addEventListener('play', loadAds);
  wistiaVideoObject.bind('widthchange', resizeAd);
};

const initializeWistiaAds = (networkId, targetingData, autoplay) => {
  enableAutoplay = autoplay;

  /*
    The javascript player API docs can be found here:
      https://docs.wistia.com/docs/javascript-player-api
    That's where I got this window level event queue. It lets us set an
    onReady event for these videos so we can kick off all this ad setup.
    The id param there can be a specific video or it can be '_all' to just
    hit any wistia video on the page.
  */
  /* eslint-disable no-underscore-dangle */
  window._wq = window._wq || [];
  window._wq.push({
    id: '_all',
    onReady: (videoObject) => setup(videoObject, networkId, targetingData),
  });
};

const clearWistiaListenersAndObservers = () => {
  observer.disconnect();
};

export {
  initializeWistiaAds,
  clearWistiaListenersAndObservers,
};
